import React from 'react'
import {graphql} from 'gatsby'
import PropTypes from "prop-types"
import Layout from "../layouts/layout";
import Events from '../components/events';

const EventsPage = ({
  data: {
    gcms: { pagina },
  },
}) => (
  <Layout pageTitle={pagina.titel} pageType='activity' heroImage={pagina.hero.url} overlay={pagina.hero.overlay}>
    <p className="text__intro">{pagina.intro}</p>
    <Events/>
  </Layout>
);

export const eventsQuery = graphql`
  query EventsQuery($id: ID!) {
    gcms {
      pagina(where: {id: $id }) {
        titel
        subtitel
        intro
        tekst {
          html
        }
        hero {
          url
          overlay
        }
      }
    }
  }
`;

Layout.propTypes = {
  heroImage: PropTypes.string,
  showOverlay: PropTypes.bool,
}

Layout.defaultProps = {
  heroImage: '',
  showOverlay: false,
}

export default EventsPage